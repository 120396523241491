<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-select clearable v-model="search.factory_code" @change="Search" placeholder="工厂">
            <el-option v-for="item in factorylist" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.product_code" @change="Search" placeholder="产品唯一标识">
            <el-option
              v-for="item in prolist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model="search.product_model_code" @change="Search" placeholder="产品型号唯一标识">
            <el-option
              v-for="item in promodellist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.sn_code" @change="Search" placeholder="型号sn"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddRelated">添加关联</el-button>
      </div>
    </div>

    <el-table border v-bind:data="list">
      <el-table-column label="产品名称">
        <template v-slot="scope">
          <span>{{ scope.row.product_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品型号">
        <template v-slot="scope">
          <span>{{ scope.row.product_model_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="SN标识">
        <template v-slot="scope">
          <!-- <span>{{ scope.row.sn_code }}</span> -->
          <el-input v-model="scope.row.sn_code" @change="UpdateSn(scope.row)" autocomplete="off"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="danger" size="mini" @click="Delete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.rows"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog title="添加关联" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="选择产品" prop="product_code" label-width="130px">
          <el-select v-model="form.product_code" @change="SelectPro">
            <el-option
              v-for="item in prolist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="modelshow" label="选择型号" prop="product_model_code" label-width="130px">
          <el-select v-model="form.product_model_code">
            <el-option
              v-for="item in promodellistnext"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="SN编码标识" prop="sn_code" label-width="130px">
          <el-input v-model="form.sn_code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="SN序列号位数" prop="sn_suffix_bit" label-width="130px">
          <el-input-number v-model="form.sn_suffix_bit" :min="1"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="Validate(Addconfirm)">提交</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../axios/productionManage'
import util from '../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      factory_code: '',
      factory_name: '',
      search: {
        page: 1,
        rows: 10,
        product_code: '',
        factory_code: '',
        product_model_code: '',
        sn_code: '',
        region: 'gb'
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      form: {
        factory_code: '',
        factory_name: '',
        product_code: '',
        product_name: '',
        product_model_code: '',
        product_model: '',
        sn_code: '',
        sn_suffix_bit: 5,
      },
      rules: {
        product_code: [{ required: true, message: '请选择产品', trigger: 'blur' }],
        product_model_code: [{ required: true, message: '请选择型号', trigger: 'blur' }],
        sn_code: [{ required: true, message: '请输入SN编码标识', trigger: 'blur' }],
        sn_suffix_bit: [{ required: true, message: '请输入SN序列号位数', trigger: 'blur' }],
      },
      prolist: [],
      proNameCode: {},
      promodellist: [],
      proModelNameCode: {},
      modelshow: false,
      promodellistnext: [],
      proModelNameCodenext: {},
      factorylist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetFactory () {
      api.FactoryList({
        data: {
          page: 1,
          rows: 100,
        },
        region: 'gb',
        payload_version: 'v1.0'
      }).then(res => {
        if (res.data.code == 200) {
          this.factorylist = res.data.data.list
          this.GetPro()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    SelectPro () {
      this.modelshow = true
      this.form.product_model_code = ''
      this.GetProModelNext()
    },
    // 产品
    GetPro () {
      this.api.ProductList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.prolist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.proNameCode[item.code] = item.name
          })
          this.GetProModel()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetProModelNext() {
      this.api.ProductModelList({
        page: 1,
        limit: 100,
        client_product_code: this.form.product_code
      }).then(res => {
        if (res.data.code == 200) {
          this.promodellistnext = res.data.data.data
          res.data.data.data.forEach(item => {
            this.proModelNameCodenext[item.code] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetProModel() {
      this.api.ProductModelList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.promodellist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.proModelNameCode[item.code] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.list = []
      api.FactoryProModelList({
        data: this.search,
        payload_version: 'v1.0',
        region: 'gb'
      }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.list
          this.total = res.data.data.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    AddRelated() {
      this.dialogFormVisible = true
    },
    Delete(item) {
      this.$confirm('是否删除该工厂型号关联?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      api.FactoryProModelDelete({
        data: {id: item.id},
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('删除成功')
            return
          }
          this.$message.error('删除失败'+ res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Addconfirm () {
      api.FactoryProModelCreate({
        data: {
          factory_code: this.factory_code,
          factory_name: this.factory_name,
          product_code: this.form.product_code,
          product_name: this.proNameCode[this.form.product_code],
          product_model_code: this.form.product_model_code,
          product_model: this.proModelNameCodenext[this.form.product_model_code],
          sn_code: this.form.sn_code,
          sn_suffix_bit: this.form.sn_suffix_bit,
        },
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.$message.success('关联成功')
          this.Search()
          this.dialogFormVisible = false
          return
        }
        this.$message.error('关联失败' + res.data.msg)
      })
    },
    UpdateSn (item) {
      api.FactoryProModelUpdate({
        data: {
          id: item.id,
          sn_code: item.sn_code,
        },
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.$message.success('修改成功')
          this.Search()
          return
        }
        this.$message.error('修改失败' + res.data.msg)
        this.Search()
      })
    },
    goback () {
      this.$router.push('/product/factorymanage')
    },
    ChangeTime (item) {
      return util.dateFormat(item)
    }
  },
  filters: {},
  mounted () {
    this.factory_code = this.$route.params.code.split('&')[1]
    this.search.factory_code = this.$route.params.code.split('&')[1]
    this.factory_name = this.$route.params.code.split('&')[0]
    this.Search()
  },
  created () {
    this.GetFactory()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
